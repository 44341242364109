import React from "react"
import SegueLink from "middleware/SegueLink"
import PropTypes from "prop-types"
import "marketing-website/stylus/ReadMore.styl"

const ReadMore = ({ url, external, previewText }) => {
    const getLinkText = () => (
        <span>
            {previewText ? previewText : "Keep Reading"}{" "}<i className="fa fa-chevron-right" />
        </span>
    )

    const getLinkType = () => (
        external
            ? <a href={url}>{getLinkText()}</a>
            : <SegueLink to={url}>{getLinkText()}</SegueLink>
    )

    return (
        <div className="read-more">
            {getLinkType()}
        </div>
    )
}

ReadMore.propTypes = {
    url: PropTypes.string.isRequired,
    external: PropTypes.bool,
}

export default ReadMore
