import React from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"

import SegueLink from "middleware/SegueLink"

import urls from "shared/marketing-website/constants/urls"
import "marketing-website/stylus/SchedulingForm.styl"

class SchedulingForm extends React.Component {
    componentDidMount() {
        // From issue https://github.com/QuorumUS/issues/issues/7512
        // This is the code accompanying the scheduling form iframe https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js
        // Comments are left where the code is changed
        // The 'c' function is where we add custom logic checking for the 'meetingBookSucceeded' flag
        // If true, then scheduling form is submitted and we fire universal tracking events
        const { eventLabel } = this.props

        function t(t) {
            return t.querySelectorAll("iframe").length > 0
        }

        function e() {
            return "&parentPageUrl=" + window.location.origin + window.location.pathname
        }

        function n(t) {
            var e = null;
            if (document.cookie && "" !== document.cookie)
                for (var n = document.cookie.split(";"), o = 0; o < n.length; o++) {
                    var r = n[o].trim(),
                        i = t + "=";
                    if (r.substring(0, t.length + 1) === i) {
                        e = r.substring(t.length + 1);
                        break
                    }
                }
            return e
        }

        function o(t) {
            return t ? "&parentHubspotUtk=" + t : ""
        }

        function r() {
            var t = (new Date).getTime();
            return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (e) {
                var n = (t + 16 * Math.random()) % 16 | 0;
                t = Math.floor(t / 16);
                return ("x" === e ? n : 3 & n | 8).toString(16)
            })
        }

        function i() {
            var t = window.crypto || window.msCrypto,
                e = new Uint16Array(8);
            t.getRandomValues(e);
            var n = function (t) {
                for (var e = t.toString(16); e.length < 4;) e = "0" + e;
                return e
            };
            return n(e[0]) + n(e[1]) + n(e[2]) + n(e[3]) + n(e[4]) + n(e[5]) + n(e[6]) + n(e[7])
        }

        function a() {
            var t = window.crypto || window.msCrypto;
            return "undefined" != typeof t && "undefined" != typeof t.getRandomValues && "undefined" != typeof window.Uint16Array ? i() : r()
        }

        function u() {
            var t = window.__hsUserToken || n("hubspotutk");
            if (!t) {
                var e = a();
                t = e;
                window.__hsUserToken = e
            }
            return t
        }

        function s(t) {
            var e = ["https://local.hubspot.com", "https://local.hubspotqa.com", "https://app.hubspotqa.com", "https://app.hubspot.com", "https://meetings.hubspot.com", "https://meetings.hubspotqa.com"];
            return e.indexOf(t) > -1
        }

        function c(t) {
            var e = t.message ? "message" : "data",
                n = t[e];
            s(t.origin) && n.height && (l.style.height = n.height + "px")

            // Start Quorum's custom tracking code
            const meetingFormData = t.data
            if (meetingFormData && meetingFormData.meetingBookSucceeded) {
                // Give iframe a class name for styling
                l.className = "meeting-iframe-submitted"

                const ownerDocument = l.ownerDocument.body
                const modal = ownerDocument.querySelector(".modal-dialog")

                // Adjust width based on screen size
                modal.style.width = ownerDocument.scrollWidth > 460 ? "410px" : "90%"

                // Google Analytics event tracking
                window.gtag('event', window.location.pathname, {
                    event_category: 'Demo',
                    event_label: eventLabel,
                })

                // Bing conversion tracking
                window.uetq.push({ 'ec': 'Demo', 'ea': window.location.pathname, 'el': eventLabel, 'ev': 50 })
            }
            // End Quorum's custom tracking code

        }
        var h, x = document.querySelectorAll(".meetings-iframe-container");
        for (h = 0; h < x.length; h++) {
            var p = x[h],
            d = p.dataset.src,
            l = document.createElement("iframe"),
            f = u();
            p.height = "100%";
            l.src = d + o(f) + e();
            l.width = "100%";
            l.style.minWidth = "312px";
            l.style.minHeight = "400px"; // Changed to a smaller height, previously 516px
            // l.style.height = "756px"; Commented out so we can set our own height
            l.style.border = "none";
            if (!t(p)) {
                p.prepend(l); // Changed from appendChild
                window.addEventListener("message", c)
            }
        }
    }

    render() {
        return (
            <div className="scheduling-form-main">
                <div
                    className="meetings-iframe-container"
                    data-src={`https://info.quorum.us/meetings/demos2/quorum-demo/${this.props.serializeQuery("requestDemoForm")}&embed=true`}
                >
                    <SegueLink
                        to={urls.products}
                        optionalPreSegueFunction={this.props.hideDemoModal}
                    >
                        <Button className="scheduling-product-button">
                            Learn about our Products
                        </Button>
                    </SegueLink>
                </div>
            </div>
        )
    }
}

SchedulingForm.defaultProps = {
    email: "",
    eventLabel: "Bottom",
}

SchedulingForm.propTypes = {
    email: PropTypes.string,
    eventLabel: PropTypes.string,
    hideDemoModal: PropTypes.func.isRequired
}

export default SchedulingForm
