import React from "react"
import HeroImage from "marketing-website/components/HeroImage"
import HeroImageBottomBanner from "marketing-website/components/HeroImageBottomBanner"
import ProductShow from "marketing-website/components/ProductShow"
import AsFeaturedIn from "marketing-website/components/AsFeaturedIn"
import RequestDemo from "marketing-website/components/RequestDemo"
import ClientSection from "marketing-website/components/ClientSection"
import LatestInsights from "marketing-website/containers/LatestInsights"

import { generateStaticUrl } from "imports/desktopHelperFunctions"

import HOME_PAGE_PRODUCT_VALUES from "marketing-website/constants/homePageProductValues"

import "marketing-website/stylus/HomePage.styl"

const HomePage = (props) => (
    <div className="home-page">

        <HeroImage
            imgPath={generateStaticUrl("apps/marketing-website/images/hero_images/resized/experiment-new-header.jpg")}
            title="Work Smarter and Move Faster"
            subtitle="Quorum lets you map, track, change, and report on the policy landscape, all in one place."
            customStyleClassName="home-page-hero-image"
            backgroundPositionY="40%"
            showEmailRequest
            isDemoRequest
        >
            <HeroImageBottomBanner />
        </HeroImage>
        {
            HOME_PAGE_PRODUCT_VALUES.map(section =>
                <ProductShow
                    {...section}
                    key={section.id}
                />
            )
        }
        <LatestInsights />
        <AsFeaturedIn />
        {/*
            Special case where Request Demo is rendered outside of marketing frame.
            We want the request demo form between 'AsFeaturedIn' and 'ClientSection' components
        */}
        <RequestDemo />
        {/* <ClientSection /> */}
    </div>
)

export default HomePage
