import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import classNames from "classnames"

import "marketing-website/stylus/QuoteSection.styl"

const QuoteSection = (props) => {
    const authorAndLogoColumn = (mobileScreen = false) => (
        <Col
            lg={4}
            lgOffset={props.textAlignedLeft ? 1 : 0}
            lgHidden={mobileScreen}
            md={4}
            mdOffset={props.textAlignedLeft ? 1 : 0}
            mdHidden={mobileScreen}
            sm={12}
            smHidden={!mobileScreen}
            xs={12}
            xsHidden={!mobileScreen}
            className={classNames(
                "quote-section-column",
                "author-column",
                {
                    "column-aligned-right": props.textAlignedLeft,
                    "column-aligned-left": !props.textAlignedLeft,
                }
            )}
        >
            <img
                alt={props.authorTitle}
                src={props.logo}
                style={props.specialStyles}
                className="quote-section-logo"
            />
            <p
                className="section-text-main quote-section-author"
            >
                {props.author}
            </p>
            <p
                className="section-text-main quote-section-author-title"
            >
                {props.authorTitle}
            </p>
        </Col>
    )

    return (
        <Row
            className="quote-section-main"
        >
            {
                !props.textAlignedLeft &&
                authorAndLogoColumn()
            }
            <Col
                lg={props.textAlignedLeft ? 8 : 7}
                lgOffset={props.textAlignedLeft ? 0 : 1}
                md={props.textAlignedLeft ? 8 : 7}
                mdOffset={props.textAlignedLeft ? 0 : 1}
                sm={12}
                xs={12}
                className="quote-section-column"
            >
                <p
                    className="section-text-main quote-section-text"
                >
                    <i>{props.quote}</i>
                </p>
            </Col>
            {
                props.textAlignedLeft &&
                authorAndLogoColumn()
            }
            {
                // For mobile screens, we want the author and logo column underneath the quote
                authorAndLogoColumn(true)
            }
        </Row>
    )
}

QuoteSection.defaultProps = {
    textAlignedLeft: false,
}

QuoteSection.propTypes = {
    author: PropTypes.string.isRequired,
    authorTitle: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    textAlignedLeft: PropTypes.bool,
}

export default QuoteSection
