import HeroImage from "marketing-website/components/HeroImage"
import ProductShow from "marketing-website/components/ProductShow"
import FeaturedArticle from "marketing-website/components/FeaturedArticle"

import urls from "marketing-website/constants/urls"
import { generateStaticUrl } from "imports/desktopHelperFunctions"

export const legislativeTrackingMeta = {
    title: "Legislative Tracking",
    description: "Work smarter when you track real-time updates on important legislation, regulations, issues, and dialogue in Congress, state legislatures, and more.",
}

export const legislativeTrackingHeroImage = {
    component: HeroImage,
    props: {
        backgroundPositionY: "50%",
        imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/Rotunda-Illinois.jpg"),
        subtitle: "Track real-time updates on legislation and regulations in Congress and all 50 states.",
        title: "Legislative Tracking Software",
        showEmailRequest: true,
        isDemoRequest: true,
    }
}

export const legislativeTrackingProductPanels = [
    {
        component: ProductShow,
        props: {
            id: "bill-tracking-system",
            key: "bill-tracking-system",
            textAlignedLeft: true,
            sectionMainHeader: "Easy-to-Use Legislative Tracking",
            sectionLeadHeader: "Federal, State, and EU",
            sectionText: "Work smarter with customizable tracking for everyone on your team across bills, regulations, social media, and more.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/legislative-tracking/bill_tracking_system.png"),
            altText: "bill-tracking-system",
            secondaryCTA: {
                link: "/resources/legislative-tracking-modernize-government-relations/280/",
                label: "Modernize Your Legislative Tracking"
            }
        }
    },
    {
        component: ProductShow,
        props: {
            id: "social-media-monitoring",
            key: "social-media-monitoring",
            textAlignedLeft: false,
            theme: "section-grey",
            sectionMainHeader: "Comprehensive Social Media Monitoring",
            sectionLeadHeader: "Never Miss a Mention of Your Issues or Organization",
            sectionText: "It’s not enough to only follow bills. Quorum’s database ensures you have access to all statements from public officials mentioning your issues or organization across their press releases, newsletters to constituents, tweets, Facebook posts, and more.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/legislative-tracking/social_media_monitoring.png"),
            altText: "social-media-monitoring",
            secondaryCTA: {
                link: "/best-practices/social-media-government-relations-strategy/272/",
                label: "Turning Social Media Into Actionable Government Relations Strategy"
            }
        }
    },
    {
        component: ProductShow,
        props: {
            id: "regulatory-data",
            key: "regulatory-data",
            textAlignedLeft: true,
            theme: "section-blue",
            sectionMainHeader: "Regulatory Data",
            sectionLeadHeader: "Monitor the full lifecycle of a rule",
            sectionText: "Be alerted in real time whenever there’s a hearing scheduled, related document, or a status update to a regulation you follow. Quorum pulls new data multiple times per day from sources including state and federal registers, the Office of Information and Regulatory Affairs (OIRA), the Office of Management and Budget (OMB), regulations.gov, and more so that you stay connected all the way through the regulatory process.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/regulations/regs-profile.png"),
            altText: "regulatory-data",
            secondaryCTA: {
                link: "/regulations/",
                label: "Learn More"
            }
        }
    },
    {
        component: ProductShow,
        props: {
            id: "automated-email-alerts",
            key: "automated-email-alerts",
            textAlignedLeft: false,
            sectionMainHeader: "Automated Email Alerts",
            sectionLeadHeader: "Get Information When It Matters",
            sectionText: "Set up email alerts on anything in Quorum—changes in legislation, committee hearings, or new votes—so you have time to focus on what’s important instead of worrying about whether or not you’re following the latest bill.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/legislative-tracking/automated_email_alerts.png"),
            altText: "automated-email-alerts",
            secondaryCTA: {
                link: "/case-studies/crisis-communications-trump-tweet/147/",
                label: "Learn How Toyota Used Quorum in Rapid Response to Trump's Tweet"
            }
        }
    },
    {
        component: ProductShow,
        props: {
            id: "reporting-and-analytics",
            key: "reporting-and-analytics",
            textAlignedLeft: true,
            theme: "section-grey",
            sectionMainHeader: "Powerful Reporting and Analytics",
            sectionLeadHeader: "Identify Insights At Scale",
            sectionText: "Make sense of thousands of public statements, bills, documents, and relationship notes by creating charts that plot trends over time, across geographies, and by legislator. Download visualizations and reports with a single click to share insights with your team.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/legislative-tracking/reporting_and_analytics.png"),
            altText: "reporting-and-analytics",
            secondaryCTA: {
                label: "See Quorum's Reporting in Action"
            }
        }
    },
    {
        component: FeaturedArticle,
        props: {
            id: "featured-products",
            key: "featured-products",
            title: "Featured Products",
            leadTitle: "Quorum’s integrated platform makes it easy to track legislation and regulations across multiple regions and continents. Learn how you can monitor issues in:",
            imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/main_banner.jpg"),
            external: false,
            productLinks: [
                {
                    name: "Federal",
                    link: urls.federalProduct
                },
                {
                    name: "State",
                    link: urls.stateProduct
                },
                {
                    name: "Local",
                    link: urls.localProduct
                },
                {
                    name: "EU",
                    link: urls.euProduct
                },
            ]
        },
    }
]

const LEGISLATIVE_TRACKING_PAGE_VALUES = {
    meta: legislativeTrackingMeta,
    componentDetails: [
        legislativeTrackingHeroImage,
        ...legislativeTrackingProductPanels
    ],
}

export default LEGISLATIVE_TRACKING_PAGE_VALUES
