import React, { lazy, Suspense, useEffect, useState } from "react"
import PropTypes from "prop-types"
import "marketing-website/stylus/LoginForm.styl"
import LoginType from "shared/marketing-website/constants/loginType"
import regexes from "shared/imports/regex"
import { parseHtmlToReact } from "app/static/frontend/imports/desktopHelperFunctions"

import { LoginPageFooter } from "marketing-website/components/LoginPageFooter"

import { Anchor, Button, Icon, LinkCallToAction, TextInput } from "quorumdesign"

import { Brand, QuorumTheme } from "app/static/frontend/design-constants"

import * as S from "marketing-website/components/LoginFormStyle"

export const LazyPasswordStrength = lazy(
    () => import(/* webpackChunkName: "PasswordStrength" */ "frontend/accounts/components/PasswordStrength"),
)

const LoginForm = ({
    // The value of the LoginType 'enum'
    loginTypeValue,
    // A boolean specifying if an input field and submit button should be rendered
    showForm,
    // An object containing the keys 'error' and 'text'.
    // 'error' specifying if it is an error message
    // 'text' for the text of the message.
    bannerMessage,
    // An array of input field objects. Each object has properties 'key', 'placeholder', 'fieldName', and 'formType'
    // fieldName: Name of the field in the reducer (e.g. username, twoFactorPassword)
    // formType: Type of the input tag (e.g. text, password)
    inputForms,
    // Boolean specifying if user is on the 'reset password' form and if password is defined
    shouldShowPasswordStrength,
    // If defined, will render a back button that will render the previous form
    previousFormValue,
    // Text on button
    buttonText,
    // The url that this form will POST to. The forms where showForm === false, no need to specify a postUrl
    postUrl,
    // Boolean specifying if submit button should be disabled
    submitButtonDisabled,
    // Boolean specifying if waiting on request to resolve
    loading,
    // Boolean for when we receive an error after login submission. Will shake entire form
    error,
    // Boolean specifying if users should be able to see the link to the 'forgot password' form
    showForgotPasswordLink,
    // Function to update the form values in store
    updateForm,
    // Function that changes what step in the login process we are in, and changes the form accordingly
    updateLoginStep,
    // Submits form, sends all information in the store to the postUrl
    submitLoginForm,
    // Function that achieves the same function as updateLoginStep,
    // but will have animations between rendering each form
    // Currently, the steps that do not have animations are
    // 1) after the users input wrong information and see the 'invalid' form.
    // 2) Clicking 'Forgot username/password'
    animateNextStep,
    // Function to focus on input field after clicking a button
    inputFieldFocus,
    // Boolean specifying if users should be able to see the link to the 'single sign on' form
    showSSOLink,
    // Big icon to show in the center of the login form
    icon,
    // Function to clear the error state on the form manually
    clearFormError,
    // This will create a formValues object with username, password, and twoFactorPassword key/pairs
    ...formValues
}) => {
    const [capsLockState, setCapsLockState] = useState(false)

    const handleChange = (formKey) => (e) => {
        clearFormError("loginPage")
        updateForm("loginPage", { [formKey]: e.currentTarget.value })
    }

    const validateEmail = () => {
        regexes.emailValidationRegex.exec(formValues.username)
            ? submitLoginForm(postUrl)
            : updateLoginStep(LoginType.forgotPasswordInvalidEmail.value)
    }

    const submitForm = () => {
        // POST the username, password,
        // and two factor token to the api endpoint
        switch (loginTypeValue) {
            case LoginType.passwordBeforeTwoFactor.value:
                break
            case LoginType.forgotPassword.value:
            case LoginType.forgotPasswordInvalidEmail.value:
                validateEmail()
                break
            default:
                submitLoginForm(postUrl)
        }
        inputFieldFocus()
    }

    const handleEnter = (e) => {
        if (e.key === "Enter" && !loading) {
            submitForm()
        }
    }

    const goToForgotPassword = () => {
        updateLoginStep(LoginType.forgotPassword.value)
        inputFieldFocus()
    }

    const goToSSO = () => {
        updateLoginStep(LoginType.singleSignOn.value)
        inputFieldFocus()
    }

    const goToLogin = () => {
        updateLoginStep(LoginType.login.value)
        inputFieldFocus()
    }

    const capsLockListener = (e) => {
        setCapsLockState(e.getModifierState("CapsLock"))
    }

    useEffect(() => {
        window.addEventListener("keypress", capsLockListener)
        return () => window.removeEventListener("keypress", capsLockListener)
    }, [])

    return (
        <S.Container>
            <S.LoginBox>
                {/* back to login */}
                {
                    // If previousFormValue exists, then we render a 'Back' button that will take the user back to the first login page.
                    // There will be a previousFormValue for all forms except initial username/email forms
                    previousFormValue && (
                        <LinkCallToAction onClick={goToLogin} text="Back to Log In" leftArrow type="md" />
                    )
                }

                <Icon
                    icon="quorum-logo"
                    iconFamily="q"
                    style={{ width: "2rem", fontSize: "2rem", color: QuorumTheme.colors.violet[5] }}
                />

                <S.Heading>
                    {bannerMessage && bannerMessage.subtitle && (
                        <S.LoginBanner data-cy="login-banner">{bannerMessage.subtitle}</S.LoginBanner>
                    )}
                    {icon && (
                        <S.IconContainer>
                            <Icon icon={icon} iconFamily="fad" color={Brand.ULTRAVIOLET} />
                        </S.IconContainer>
                    )}
                    <S.Text helper>
                        {bannerMessage && (
                            <S.BannerSection>
                                <S.BannerMessage data-cy="banner-message">
                                    {parseHtmlToReact(bannerMessage.text, 1, false)}
                                </S.BannerMessage>
                                {bannerMessage.showForgotPasswordLink && (
                                    <S.Link>
                                        <Anchor onClick={goToForgotPassword}>Forgot username or password</Anchor>
                                    </S.Link>
                                )}
                            </S.BannerSection>
                        )}
                    </S.Text>
                </S.Heading>
                {showForm && (
                    <S.Login>
                        {inputForms.map((form) => (
                            <TextInput
                                key={form.fieldName}
                                id={form.fieldName}
                                label={form.fieldLabel}
                                dataCy={`login-form-${form.fieldName}`}
                                placeholder={form.placeholder}
                                onChange={handleChange(form.fieldName)}
                                onEnter={!submitButtonDisabled && handleEnter}
                                isPassword={form.formType === "password"}
                                value={formValues[form.fieldName] || ""}
                                labelTopMargin={"20px"}
                                errorMessage={(error && form.errorMessage) || error}
                                warningMessage={capsLockState && "The all-caps lock key is on"}
                                labelStyle={{ fontSize: ".875rem", fontWeight: 500, color: QuorumTheme.colors.gray[7] }}
                                wrapperStyle={{ border: `1px solid ${QuorumTheme.colors.gray[5]}` }}
                                showPasswordStyle={{ fontSize: ".875rem" }}
                            />
                        ))}
                        {shouldShowPasswordStrength && (
                            <div
                                className="login-form-password-strength"
                                // we need to check the password strength with this component, but we
                                // don't want to show the password strength bar
                                style={{ display: "none" }}
                            >
                                <Suspense fallback={<React.Fragment />}>
                                    <LazyPasswordStrength
                                        password={formValues["password"] || ""}
                                        onChange={(score) => updateForm("loginPage", { passwordStrength: score })}
                                    />
                                </Suspense>
                            </div>
                        )}
                        {showForgotPasswordLink && (
                            <S.SmallForgotPasswordLink>
                                <Anchor onClick={goToForgotPassword}>Forgot username or password</Anchor>
                            </S.SmallForgotPasswordLink>
                        )}
                    </S.Login>
                )}

                <S.Footer>
                    {showForm && (
                        <Button
                            text={buttonText}
                            type="primary"
                            onClick={submitForm}
                            disabled={submitButtonDisabled}
                            loading={loading}
                            style={{ width: "100%", borderRadius: "2rem" }}
                        />
                    )}
                    {showSSOLink && (
                        <S.Link>
                            <Anchor onClick={goToSSO} style={{ margin: "4px", fontSize: "1rem" }}>
                                Log in using SSO
                            </Anchor>
                        </S.Link>
                    )}
                </S.Footer>
            </S.LoginBox>
            <LoginPageFooter />
        </S.Container>
    )
}

LoginForm.defaultProps = {
    showForm: false,
    inputForms: [],
    formType: "text",
    buttonText: "",
    postUrl: "",
    showForgotPasswordLink: false,
    showSSOlink: false,
}

LoginForm.propTypes = {
    loginTypeValue: PropTypes.number.isRequired,
    showForm: PropTypes.bool,
    inputForms: PropTypes.array,
    buttonText: PropTypes.string,
    postUrl: PropTypes.string,
    showForgotPasswordLink: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    updateForm: PropTypes.func.isRequired,
    updateLoginStep: PropTypes.func.isRequired,
    submitLoginForm: PropTypes.func.isRequired,
    animateNextStep: PropTypes.func.isRequired,
    inputFieldFocus: PropTypes.func.isRequired,
    shouldShowPasswordStrength: PropTypes.bool.isRequired,
    showSSOlink: PropTypes.bool,
    clearFormError: PropTypes.func.isRequired,
}

export default LoginForm
