import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import STAKEHOLDER_ENGAGEMENT_PAGE_VALUES from "marketing-website/constants/stakeholderEngagementPageValues"
import LEGISLATIVE_TRACKING_PAGE_VALUES from "marketing-website/constants/legislativeTrackingPageValues"
import GRASSROOTS_ADVOCACY_PAGE_VALUES from "marketing-website/constants/grassrootsAdvocacyPageValues"
import LEGISLATIVE_TRACKING_SOFTWARE_PAGE_VALUES from "marketing-website/constants/legislativeTrackingSoftwarePageValues"

import FEDERAL_PRODUCT_VALUES from "marketing-website/constants/federalProductValues"
import PRODUCTS_PAGE_VALUES from "marketing-website/constants/productsPageValues"
import STATE_PRODUCT_VALUES from "marketing-website/constants/stateProductValues"
import GRASSROOTS_PRODUCT_VALUES from "marketing-website/constants/grassrootsProductValues"
import LOCAL_PRODUCT_VALUES from "marketing-website/constants/localProductValues"
import STAKEHOLDER_PRODUCT_VALUES from "marketing-website/constants/stakeholderProductValues"
import INTERNATIONAL_PRODUCT_VALUES from "marketing-website/constants/internationalProductValues"
import EU_PRODUCT_VALUES from "marketing-website/constants/euProductValues"
import MOBILE_PRODUCT_VALUES from "marketing-website/constants/mobileProductValues"
import MOBILE_EU_PRODUCT_VALUES from "marketing-website/constants/mobileEuProductValues"
import DESKTOP_PRODUCT_VALUES from "marketing-website/constants/desktopProductValues"
import REGULATIONS_PRODUCT_VALUES from "marketing-website/constants/regulationsProductValues"
import SECURITY_PAGE_VALUES from "marketing-website/constants/securityPageValues"

const ProductPage = ({ meta, componentDetails }) => {
    const renderComponents = () => (
        componentDetails.map((componentDetailObj) => {
            const Component = componentDetailObj.component
            return <Component {...componentDetailObj.props} />
        })
    )

    const title = `${meta.title} | Quorum`

    return (
        <div className="product-page">
            <Helmet>
                <title>{title}</title>
                {/*
                    Facebook Seo Stuff
                    - https://developers.facebook.com/docs/sharing/webmasters#markup
                */}
                <meta
                    property="og:title"
                    content={title}
                />
                {meta.description &&
                    <meta
                        property="og:description"
                        content={meta.description}
                    />
                }

                {/*
                    Twitter Seo Stuff
                    - https://dev.twitter.com/cards/markup
                */}
                <meta
                    name="twitter:title"
                    content={title}
                />
                {meta.description &&
                    <meta
                        name="twitter:description"
                        content={meta.description}
                    />
                }
            </Helmet>
            {renderComponents()}
        </div>
    )
}

ProductPage.propTypes = {
    meta: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
    }),
    componentDetails: PropTypes.array.isRequired,
}

export const StakeholderEngagementPage = () => <ProductPage {...STAKEHOLDER_ENGAGEMENT_PAGE_VALUES} />
export const LegislativeTrackingPage = () => <ProductPage {...LEGISLATIVE_TRACKING_PAGE_VALUES} />
export const GrassrootsAdvocacyPage = () => <ProductPage {...GRASSROOTS_ADVOCACY_PAGE_VALUES} />

export const LegislativeTrackingSoftwarePage = () => <ProductPage {...LEGISLATIVE_TRACKING_SOFTWARE_PAGE_VALUES} />

export const ProductsPage = () => <ProductPage {...PRODUCTS_PAGE_VALUES} />
export const FederalProduct = () => <ProductPage {...FEDERAL_PRODUCT_VALUES} />
export const StateProduct = () => <ProductPage {...STATE_PRODUCT_VALUES} />
export const GrassrootsProduct = () => <ProductPage {...GRASSROOTS_PRODUCT_VALUES} />
export const LocalProduct = () => <ProductPage {...LOCAL_PRODUCT_VALUES} />
export const StakeHolderProduct = () => <ProductPage {...STAKEHOLDER_PRODUCT_VALUES} />
export const InternationalProduct = () => <ProductPage {...INTERNATIONAL_PRODUCT_VALUES} />
export const EuProduct = () => <ProductPage {...EU_PRODUCT_VALUES} />
export const MobileProduct = () => <ProductPage {...MOBILE_PRODUCT_VALUES} />
export const MobileEuProduct = () => <ProductPage {...MOBILE_EU_PRODUCT_VALUES} />
export const DesktopProduct = () => <ProductPage {...DESKTOP_PRODUCT_VALUES} />
export const RegulationsProduct = () => <ProductPage {...REGULATIONS_PRODUCT_VALUES} />
export const SecurityPage = () => <ProductPage {...SECURITY_PAGE_VALUES} />
