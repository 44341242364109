import React from "react"
import { Helmet } from "react-helmet"
import baseSeoValues from "marketing-website/constants/seoValues"

const BaseHelmet = () => (
    <Helmet>
        <title>{baseSeoValues.title}</title>

        <meta charSet="utf-8" />
        <meta name="author" content="Quorum Analytics, Inc." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={baseSeoValues.baseDescription} />

        <meta name="apple-itunes-app" content="app-id=1084463500" />
        <meta name="keywords" content={baseSeoValues.keywords} />
        <link rel="icon" href={baseSeoValues.faviconPath} />

        {/*
            Fonts from fonts.google.com
            ':400,300,600,700,800' fetches the font at the specified font-weights
        */}
        <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800"
            rel="stylesheet"
            type="text/css"
        />

        <script
            charset="utf-8"
            type="text/javascript"
            src="//js.hsforms.net/forms/v2.js"
        />

        {/*
            Facebook Seo Stuff
            - https://developers.facebook.com/docs/sharing/webmasters#markup
        */}
        <meta property="og:title" content={baseSeoValues.title} />
        <meta property="og:site_name" content="Quorum" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={baseSeoValues.socialDescription} />
        <meta property="og:image" content={baseSeoValues.socialBannerPath} />

        {/*
            Twitter Seo Stuff
            - https://dev.twitter.com/cards/markup
        */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:domain" content="quorum.us" />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={baseSeoValues.title} />
        <meta name="twitter:description" content={baseSeoValues.socialDescription} />
        <meta name="twitter:image" content={baseSeoValues.socialBannerPath} />

    </Helmet>
)

export default BaseHelmet
