import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import {
    Row,
    Col,
    Button,
} from "react-bootstrap"
import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"
import CAREERS_PAGE_VALUES from "marketing-website/constants/careersPageValues"
import HeroImage from "marketing-website/components/HeroImage"
import PillWrapper from "marketing-website/components/PillWrapper"
import { slugify, scrollToJobListings } from "marketing-website/constants/functions"
import "marketing-website/stylus/CareersPage.styl"

const mapStateToProps = (state, props) => ({
    availablePositions: selectors.selectAvailablePositions(state),
})

class CareersPage extends React.Component {
    componentDidMount() {
        this.props.fetchAvailablePositions()
    }

    clickTopPanelCTA = () => {
        // Google Analytics event tracking
        window.gtag('event', window.location.pathname, {
            event_category: 'Careers',
            event_label: 'Top Panel Join Our Team',
        })

        scrollToJobListings()
    }

    clickBottomPanelCTA = () => {
        // Google Analytics event tracking
        window.gtag('event', window.location.pathname, {
            event_category: 'Careers',
            event_label: 'Bottom Panel Join Our Team',
        })

        scrollToJobListings()
    }

    fullWidthText = (header, text) => (
        <Col
            md={10}
            sm={10}
            xs={10}
            mdOffset={1}
            smOffset={1}
            xsOffset={1}
            className="careers-page-full-width"
        >
            {
                header &&
                    <h2
                        className="careers-page-full-header"
                    >
                        {header}
                        <hr />
                    </h2>
            }
            {
                text &&
                <div
                    className="careers-page-full-text"
                >
                    {text}
                </div>
            }
        </Col>
    )

    positionsTracking = (department, title) =>
        e => {
            // Google Analytics event tracking
            window.gtag('event', window.location.pathname, {
                event_category: 'Careers',
                event_label: `${department} - ${title}`,
            })
        }

    availablePositionsTiles = (departments) => {
        const { availablePositions } = this.props

        return (
            <div className="available-positions-main">
                {
                    departments.map((department) => {
                        return availablePositions[department] &&
                            availablePositions[department].map(job => (
                                <Col
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    key={job.title}
                                >
                                    <a
                                        target="_blank"
                                        href={job.absolute_url}
                                        className={`position-thumbnail ${slugify(department)}`}
                                        onClick={this.positionsTracking(department, job.title)}
                                    >
                                        <div
                                            className="position-title"
                                        >
                                            {`${job.title} - ${job.location.name}`}
                                        </div>
                                        <div
                                            className="position-department"
                                        >
                                            {department}
                                        </div>
                                    </a>
                                </Col>
                            ))

                    })
                }
            </div>
        )
    }

    // We include our Glassdoor score/button because GD will rank us more highly if we include it
    renderWhyWorkAtQuorumSection = whyWorkAtQuorum => (
        <div
            className="careers-page-why-quorum"
        >
            <Row>
                { this.fullWidthText(whyWorkAtQuorum.header, whyWorkAtQuorum.text) }
                <Col
                    md={10}
                    sm={10}
                    xs={10}
                    mdOffset={1}
                    smOffset={1}
                    xsOffset={1}
                    className="why-quorum-cta-wrapper"
                >
                    <Button
                        onClick={this.clickTopPanelCTA}
                        className="careers-page-cta-button"
                    >
                        {whyWorkAtQuorum.buttonText}
                    </Button>
                </Col>
            </Row>
            <a href='https://www.glassdoor.com/Overview/Working-at-Quorum-EI_IE1137932.11,17.htm'>
                <img src='https://www.glassdoor.com/api/widget/verticalStarRating.htm?e=1137932' />
            </a>
            <div
                className="why-quorum-image-banner"
            >
                {
                    whyWorkAtQuorum.images.map(image =>
                        <div
                            key={image.imgPath}
                            className="why-quorum-image"
                            style={{
                                backgroundImage: `url(${image.imgPath})`
                            }}
                        />
                    )
                }
            </div>
        </div>
    )

    renderAvailablePositionsSection = availablePositions => (
        <Row
            className="careers-page-positions"
        >
            {this.fullWidthText(availablePositions.header)}

            {
                this.props.availablePositions.size === 0
                    ? <Col
                        md={10}
                        sm={10}
                        xs={10}
                        mdOffset={1}
                        smOffset={1}
                        xsOffset={1}
                        className="available-positions-none"
                    >
                        {availablePositions.noAvailablePositionsText}
                    </Col>
                    : this.availablePositionsTiles(availablePositions.departments)
            }
        </Row>
    )

    renderBenefitsSection = benefits => (
        <Row
            className="careers-page-benefits"
        >
            {this.fullWidthText(benefits.header)}
            <Col
                lg={8}
                md={12}
                sm={12}
                xs={12}
                lgOffset={0}
                mdOffset={0}
                smOffset={0}
                xsOffset={0}
                className="benefits-pills-wrapper"
            >
                <PillWrapper
                    pills={benefits.items}
                />
            </Col>
            <Col
                md={4}
                sm={12}
                xs={12}
                mdOffset={0}
                smOffset={0}
                xsOffset={0}
                className="benefits-image-wrapper"
            >
                {
                    benefits.images.map(image =>
                        <div
                            key={image.imgPath}
                            className="benefits-image"
                            style={{
                                backgroundImage: `url('${image.imgPath}')`
                            }}
                        />
                    )
                }
            </Col>
        </Row>
    )

    renderFacilitiesSection = facilities => (
        <Row
            className="careers-page-facilities"
        >
            <div
                className="facilities-right-section"
            >
                <div
                    className="facilities-text-section"
                >
                    <div
                        className="facilities-text"
                    >
                        <div
                            className="facilities-header"
                        >
                            { facilities.header }
                        </div>
                        <div
                            className="facilities-description"
                        >
                            { facilities.text }
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    )

    renderJoinOurTeamSection = (joinOurTeam) => (
        <Row
            className="careers-page-join-team"
        >
            { this.fullWidthText(joinOurTeam.header, joinOurTeam.text) }
            <Col
                md={10}
                sm={10}
                xs={10}
                mdOffset={1}
                smOffset={1}
                xsOffset={1}
            >
                <Button
                    onClick={this.clickBottomPanelCTA}
                    className="careers-page-cta-button"
                >
                    { joinOurTeam.buttonText }
                </Button>
            </Col>
        </Row>
    )

    render() {
        const {
            whyWorkAtQuorum,
            availablePositions,
            benefits,
            facilities,
            joinOurTeam,
        } = CAREERS_PAGE_VALUES.sections

        return (
            <div
                className="careers-page-main"
            >
                <Helmet>
                    <title>
                        {CAREERS_PAGE_VALUES.metaTitle}
                    </title>
                    <meta
                        name="description"
                        content={CAREERS_PAGE_VALUES.metaDescription}
                    />
                    <meta
                        property="og:title"
                        content={CAREERS_PAGE_VALUES.metaTitle}
                    />
                    <meta
                        property="og:description"
                        content={CAREERS_PAGE_VALUES.metaDescription}
                    />
                </Helmet>
                <HeroImage
                    imgPath={CAREERS_PAGE_VALUES.imgPath}
                    title={CAREERS_PAGE_VALUES.title}
                    backgroundPositionY="40%"
                />
                { this.renderWhyWorkAtQuorumSection(whyWorkAtQuorum) }
                { this.renderAvailablePositionsSection(availablePositions) }
                { this.renderBenefitsSection(benefits) }
                {/* Comment out facilities section until we update information and images */}
                {/* { this.renderFacilitiesSection(facilities) } */}
                { this.renderJoinOurTeamSection(joinOurTeam) }
            </div>
        )
    }
}

CareersPage.defaultProps = {
    availablePositions: {},
}

CareersPage.propTypes = {
    fetchAvailablePositions: PropTypes.func.isRequired,
    availablePositions: PropTypes.object,
}

export default connect(mapStateToProps, actionCreators)(CareersPage)
