import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import swal from 'sweetalert'

import {
    Row,
    Col,
    Button,
} from "react-bootstrap"

import FormInput from "marketing-website/components/FormInput"
import SingleLineInput from "marketing-website/components/SingleLineInput"
import MultiLineInput from "marketing-website/components/MultiLineInput"
import SelectInput from "marketing-website/components/SelectInput"

import * as actionCreators from "shared/marketing-website/action-creators"
import { PaidSearchDemoFormConnection } from "shared/marketing-website/selectors"

import { alwaysValid, emailValidation, phoneNumberValidation } from "shared/imports/validationFunctions"
import { checkSubmission } from "marketing-website/constants/functions"
import {
    readCookieByKey,
    downloadPDFCrossDomain,
} from "imports/desktopHelperFunctions"

import "marketing-website/stylus/PaidSearchDemoForm.styl"

const PaidSearchDemoForm = (props) => {
    const navigateToGatedContentUrl = () => {
        if (props.gatedContentUrl) {
            window.open(props.gatedContentUrl, '_blank')
        }
    }

    const validateAndSubmit = () => {
        const [allDefined, allValid] = checkSubmission(props.formValues, props.validationDict)

        if (!allDefined) {
            swal({
                title: "Please fill out all the fields.",
                icon: "warning",
            })
        } else if (!allValid) {
            // swall invalid forms
            swal({
                title: "Please make sure all fields have valid input.",
                icon: "warning",
            })
        } else {
            // in the case that we can submit
            const data = {
                firstname: props.formValues.get("firstName"),
                lastname: props.formValues.get("lastName"),
                email: props.formValues.get("email"),
                phone: props.formValues.get("phone"),
                company: props.formValues.get("company"),
                hs_context: JSON.stringify({
                    hutk: readCookieByKey('hubspotutk'),
                    pageUrl: window.location.href,
                    pageName: document.title
                })
            }

            if (props.blogDetailPost) {
                data['primary_product_persona'] = props.formValues.get("primary_product_persona")
            } else {
                data['salutation'] = props.formValues.get("tell_us_what_you_re_looking_for")
                data['jobtitle'] = props.formValues.get("jobtitle")
            }

            const eventCategory = props.blogDetailPost ? "Download Content" : "Demo"
            const eventLabel = "Full Header"

            // Google Analytics event tracking
            gtag('event', window.location.pathname, {
                event_category: eventCategory,
                event_label: eventLabel,
            })

            // Bing conversion tracking
            window.uetq.push({ 'ec': eventCategory, 'ea': window.location.pathname, 'el': eventLabel, 'ev': 50 })

            props.submitForm(props.formUrl, data, props.formKey, props.blogDetailPost).then(() => {
                if (props.blogDetailPost) {
                    if (props.whitePaperLink) {
                        downloadPDFCrossDomain(props.whitePaperLink, props.slug).then(() => {
                            navigateToGatedContentUrl()
                        })
                    } else {
                        navigateToGatedContentUrl()
                    }
                } else {
                    props.showDemoModal(eventLabel)
                }
            })

        }
    }

    return (
        <Col
            md={5}
            mdOffset={1}
            className="search-request-demo-main"
        >
            <div className="search-request-demo-text">
                <h3 className="search-request-demo-main-header">
                    {props.formHeader || "Request Demo"}
                </h3>
                {/* Kill this paragraph for now. May add back later */}
                {/* <h4 className="search-request-demo-text-main">
                    Public affairs professionals should have modern software built for them. Request a demo today to learn why.
                </h4> */}
            </div>
            <Row>
                <Col
                    md={10}
                    mdOffset={1}
                    sm={10}
                    smOffset={1}
                    xs={10}
                    xsOffset={1}
                    className="search-request-demo-form-column"
                >
                    <form
                        className="search-request-demo-form"
                    >
                        <FormInput
                            placeHolderText="First Name"
                            fieldKey="firstName"
                            updateForm={props.updateForm}
                            formValues={props.formValues}
                            validationFunction={alwaysValid}
                            formKey={props.formKey}
                            presentationComponent={SingleLineInput}
                            explicitColSm={6}
                            explicitColXs={6}
                        />
                        <FormInput
                            placeHolderText="Last Name"
                            fieldKey="lastName"
                            updateForm={props.updateForm}
                            formValues={props.formValues}
                            validationFunction={alwaysValid}
                            formKey={props.formKey}
                            presentationComponent={SingleLineInput}
                            explicitColSm={6}
                            explicitColXs={6}
                        />
                        <FormInput
                            placeHolderText="Work Email"
                            fieldKey="email"
                            updateForm={props.updateForm}
                            formValues={props.formValues}
                            validationFunction={emailValidation}
                            formKey={props.formKey}
                            presentationComponent={SingleLineInput}
                            explicitColSm={6}
                            explicitColXs={6}
                        />
                        <FormInput
                            placeHolderText="Phone Number"
                            fieldKey="phone"
                            updateForm={props.updateForm}
                            formValues={props.formValues}
                            validationFunction={phoneNumberValidation}
                            formKey={props.formKey}
                            presentationComponent={SingleLineInput}
                            explicitColSm={6}
                            explicitColXs={6}
                        />
                        <FormInput
                            placeHolderText="Company"
                            fieldKey="company"
                            updateForm={props.updateForm}
                            formValues={props.formValues}
                            validationFunction={alwaysValid}
                            formKey={props.formKey}
                            presentationComponent={SingleLineInput}
                            explicitColSm={6}
                            explicitColXs={6}
                        />
                        {
                            props.blogDetailPost
                                ? [
                                    <FormInput
                                        placeHolderText="Primary Focus Area"
                                        fieldKey="primary_product_persona"
                                        updateForm={props.updateForm}
                                        formValues={props.formValues}
                                        validationFunction={alwaysValid}
                                        formKey={props.formKey}
                                        presentationComponent={SelectInput}
                                        explicitColSm={6}
                                        explicitColXs={6}
                                    />,
                                    // Empty div for styling purposes.
                                    // Component is expecting a full width textarea to style
                                    <div />
                                ]
                                : [
                                    <FormInput
                                        placeHolderText="Job Title"
                                        fieldKey="jobtitle"
                                        updateForm={props.updateForm}
                                        formValues={props.formValues}
                                        validationFunction={alwaysValid}
                                        formKey={props.formKey}
                                        presentationComponent={SingleLineInput}
                                        explicitColSm={6}
                                        explicitColXs={6}
                                    />,
                                    <FormInput
                                        placeHolderText="Tell us what you're looking for."
                                        fieldKey="tell_us_what_you_re_looking_for"
                                        updateForm={props.updateForm}
                                        formValues={props.formValues}
                                        validationFunction={alwaysValid}
                                        formKey={props.formKey}
                                        presentationComponent={MultiLineInput}
                                        rows={2}
                                    />
                                ]
                        }
                    </form>
                    <Button
                        className="search-request-demo-submit btn-lg btn-block"
                        onClick={validateAndSubmit}
                    >
                        {props.ctaButtonText || 'Schedule Demo'}
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

PaidSearchDemoForm.propTypes = {
    // Form props
    formKey: PropTypes.string,
    formUrl: PropTypes.string,
    formValues: PropTypes.object.isRequired,
    showDemoModal: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    validationDict: PropTypes.object.isRequired,

    // Blog detail props
    blogDetailPost: PropTypes.bool,
    ctaButtonText: PropTypes.string,
    formHeader: PropTypes.string,
    gatedContentUrl: PropTypes.string,
    slug: PropTypes.string,
    whitePaperLink: PropTypes.string,
}

export default connect(PaidSearchDemoFormConnection, actionCreators)(PaidSearchDemoForm)
