import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { detect } from "detect-browser"
import * as actionCreators from "shared/marketing-website/action-creators"
import * as desktopActionCreators from "marketing-website/action-creators"
import { LoginPageSelectors } from "shared/marketing-website/selectors"
import LoginForm from "marketing-website/components/LoginForm"
import LOGIN_PAGE_VALUES from "shared/marketing-website/constants/loginPageValues"
import { LoginType, loginTypeLookup } from "shared/marketing-website/constants/loginType"

import "marketing-website/stylus/LoginPage.styl"
// For the login page animations
import "animate.css"

class LoginPage extends React.Component {
    componentDidMount() {
        // Check if the user has navigated to the password recovery form.
        // This is a special case because of the userCode param
        const userCode = this.props.params.userCode
        if (userCode) {
            this.props.updateUserCode(userCode)
            return this.props.updateLoginStep(LoginType.resetPassword.value)
        }

        // Check if the user navigated to a specific login page (e.g. /login/forgot/),
        // and if we allowed them to be able to navigate to that specific form.
        // Otherwise move on to browser check
        const pathName = this.props.location.pathname

        const LoginTypeEnum = loginTypeLookup("pathName", pathName)

        if (LoginTypeEnum && LoginTypeEnum.canNavigate) {
            return this.props.updateLoginStep(LoginTypeEnum.value)
        }

        // If we are on regular login page, check their browser for compatibility
        this.browserCheck()
        this.inputFieldFocus()
    }

    componentWillUpdate(nextProps) {
        // If an url was received from a POST request, we navigate to that url.
        if (nextProps.nextUrl) {
            window.location = nextProps.nextUrl
        }

        const LoginTypeEnum = Object.values(LoginType).find((type) => nextProps.currentStep === type.value)

        // Update the window's url to correspond with current rendered form
        if (LoginTypeEnum && this.props.currentStep !== nextProps.currentStep) {
            this.props.segue(LoginTypeEnum.pathName)
        }
    }

    browserCheck = () => {
        // Check if the browser they are using is compatible
        const browser = detect()
        const unsupportedBrowser = LOGIN_PAGE_VALUES.unsupportedBrowsers[browser.name]
        const version = parseInt(browser.version)

        if (unsupportedBrowser) {
            // If the browser is blacklisted or the version is less updated than our minimum version requirement,
            // render blacklisted browser form
            if (unsupportedBrowser.blacklisted || version < unsupportedBrowser.minWarnVersion) {
                return this.props.updateLoginStep(LoginType.blacklistedBrowser.value)
            }

            // If the browser version is greater than/equal to our minimum version requirement
            // and we do not have a 'maxWarnVersion', then render the browser warning form
            if (!unsupportedBrowser.maxWarnVersion) {
                return this.props.updateLoginStep(LoginType.unsupportedBrowserLogin.value)
            }

            // If there is a 'maxWarnVersion' specified and the browser version is
            // between our minimum version requirement and 'maxWarnVersion', then render browser warning form
            if (unsupportedBrowser.maxWarnVersion && version < unsupportedBrowser.maxWarnVersion) {
                return this.props.updateLoginStep(LoginType.unsupportedBrowserLogin.value)
            }
            // If none of these conditions are met, continue to regular login form
        }
    }

    inputFieldFocus = () => {
        // Sets focus back on input field
        const inputField = document.getElementById("login-page-input-field")
        if (inputField) {
            inputField.focus()
        }
    }

    render() {
        const LoginTypeEnum = loginTypeLookup("value", this.props.currentStep) || LoginType.invalidLogin

        return (
            <div className="login-page-main">
                <div className={"login-page-main-container"}>
                    <Helmet>
                        {/* Make Responsive for Mobile devices */}
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <title>{`${LoginTypeEnum.metaTitle || "Login"} | Quorum`}</title>
                    </Helmet>
                    <LoginForm
                        clearFormError={this.props.clearFormError}
                        loginTypeValue={LoginTypeEnum.value}
                        showForm={LoginTypeEnum.showForm}
                        bannerMessage={LoginTypeEnum.bannerMessage}
                        inputForms={LoginTypeEnum.inputForms}
                        previousFormValue={LoginTypeEnum.previousFormValue}
                        buttonText={LoginTypeEnum.buttonText}
                        postUrl={LoginTypeEnum.postUrl}
                        showForgotPasswordLink={LoginTypeEnum.showForgotPasswordLink}
                        username={this.props.username}
                        password={this.props.password}
                        newPassword={this.props.newPassword}
                        shouldShowPasswordStrength={this.props.shouldShowPasswordStrength}
                        resetPasswordValid={this.props.resetPasswordValid}
                        twoFactorPassword={this.props.twoFactorPassword}
                        submitButtonDisabled={this.props.submitButtonDisabled}
                        loading={this.props.loading}
                        error={this.props.error}
                        updateForm={this.props.updateForm}
                        updateLoginStep={this.props.updateLoginStep}
                        submitLoginForm={this.props.submitLoginForm}
                        startAnimation={this.props.startAnimation}
                        endAnimation={this.props.endAnimation}
                        reverseAnimation={this.props.reverseAnimation}
                        animateNextStep={this.props.animateNextStep}
                        inputFieldFocus={this.inputFieldFocus}
                        showSSOLink={LoginTypeEnum.showSSOLink}
                        icon={LoginTypeEnum.icon}
                    />
                </div>
            </div>
        )
    }
}

LoginPage.propTypes = {
    submitLoginForm: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    updateLoginStep: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    updateUserCode: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    startAnimation: PropTypes.bool.isRequired,
    endAnimation: PropTypes.bool.isRequired,
    reverseAnimation: PropTypes.bool.isRequired,
    animateNextStep: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    twoFactorPassword: PropTypes.string.isRequired,
    shouldShowPasswordStrength: PropTypes.bool.isRequired,
    resetPasswordValid: PropTypes.bool.isRequired,
    submitButtonDisabled: PropTypes.bool.isRequired,
    segue: PropTypes.func.isRequired,
}

export default connect(LoginPageSelectors, { ...actionCreators, ...desktopActionCreators })(LoginPage)
