import HeroImage from "marketing-website/components/HeroImage"
import ProductShow from "marketing-website/components/ProductShow"

import BlueSection from "marketing-website/components/BlueSection"
import { generateStaticUrl } from "imports/desktopHelperFunctions"

const SECURITY_PAGE_VALUES = {
    meta: {
        title: "Security",
        description: "Quorum is software built for public affairs, providing one place for stakeholder engagement, legislative tracking, and grassroots advocacy.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                backgroundPositionY: "50%",
                imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/security/cybersecurity.jpg"),
                subtitle: "",
                title: "Data Security at Quorum",
            }
        },
        {
            component: ProductShow,
            props: {
                id: "our-approach",
                key: "our-approach",
                textAlignedLeft: true,
                sectionMainHeader: "Our Approach",
                sectionText: "The security and privacy of your Quorum account and the data you put in Quorum is incredibly important to us. As such, our company follows best-in-class security practices with regards to both our technical setup and our personnel to ensure that clients’ data is appropriately protected. Quorum has passed extensive security reviews at many of the world’s largest companies and hires a third party security firm to attempt to hack into its systems twice a year. Our approach to security and privacy is centered around five pillars:",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/security/our-approach.png"),
                altText: "our-approach",
                removeSecondayCTA: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "application-security",
                key: "application-security",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionMainHeader: "Application Security",
                sectionText: 'Application security ensures that Quorum’s applications—the website, mobile app, and desktop app through which our clients access Quorum—are protected. We encrypt all data in transit and at rest (and maintain an <a href="https://www.ssllabs.com/ssltest/analyze.html?d=quorum.us&s=54.83.172.43&latest" target="_blank">A rating on encryption from Qualys</a>), enforce enterprise-grade login controls including optional two-factor authentication and SSO, and have designed built-in protections against XSS, SQLi, and many other common attack patterns.',
                // swapped images with endpoint
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/security/endpoint.png"),
                altText: "application-security",
                removeSecondayCTA: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "infrastructure-security",
                key: "infrastructure-security",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Infrastructure Security",
                sectionText: "Quorum is entirely hosted on Amazon Web Services (AWS), a best-in-class infrastructure as a service provider, and uses a combination of AWS-provided, third party, and in-house systems in order to protect the servers, databases, firewalls, backups, and other components that Quorum is built on.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/security/infrastructure-white.png"),
                altText: "infrastructure-security",
                removeSecondayCTA: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "endpoint-security",
                key: "endpoint-security",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionMainHeader: "Endpoint Security",
                sectionText: "To protect the laptops and computers used by employees, we contract with Crowdstrike, an endpoint security firm used by many government organizations and Fortune 100 companies and which is well known for investigating the DCCC hacking that occurred during the 2016 Presidential campaign. Crowdstrike’s endpoint security system identifies and blocks issues before they occur and serves as a powerful monitoring and anti-virus system. Our hardened server endpoints are protected by both Crowdstrike as well as a variety of other intrusion detection and prevention systems.",
                // Swapped images with application
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/security/application.png"),
                altText: "endpoint-security",
                removeSecondayCTA: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "personnel-training",
                key: "personnel-training",
                textAlignedLeft: true,
                theme: "section-purple",
                sectionMainHeader: "Personnel Training and Policies",
                sectionText: "Even the best security systems cannot be successful if the individuals involved are not well-equipped to follow standard practices. Every Quorum employee undergoes a background check prior to the start of employment, signs a non-disclosure agreement (NDA) as part of the employment agreement, passes mandatory data security training on their first day, and adheres to strict internal access limitations with regards to client data.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/security/personnel-training-white.png"),
                altText: "personnel-training",
                removeSecondayCTA: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "legal-compliance",
                key: "legal-compliance",
                textAlignedLeft: false,
                sectionMainHeader: "Legal and Contractual Compliance",
                sectionText: "Any data that a client enters into Quorum’s system is owned by that client, not Quorum, and we do not share any information between different client accounts or with third parties. Quorum maintains complete GDPR compliance and provides guidance to its clients on how they can use Quorum’s products to remain GDPR compliant themselves.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/security/legal-compliance.png"),
                altText: "legal-compliance",
                removeSecondayCTA: true,
            }
        },
        {
            component: BlueSection,
            props: {
                text: `These standards and practices are only the tip of the iceberg. If you’d like to learn more about how Quorum’s security systems can help protect your data, please don’t hesitate to <a href="mailto:security@quorum.us">reach out</a>.`
            }
        },
    ],
}

export default SECURITY_PAGE_VALUES
