const getBlogList = {
    GET_BLOG_LIST_START: "GET_BLOG_LIST_START",
    GET_BLOG_LIST_FAIL: "GET_BLOG_LIST_FAIL",
    GET_BLOG_LIST_SUCCESS: "GET_BLOG_LIST_SUCCESS",
}

const getBlogDetail = {
    GET_BLOG_DETAIL_START: "GET_BLOG_DETAIL_START",
    GET_BLOG_DETAIL_FAIL: "GET_BLOG_DETAIL_FAIL",
    GET_BLOG_DETAIL_SUCCESS: "GET_BLOG_DETAIL_SUCCESS",
}

const getNextBlogs = {
    GET_NEXT_BLOGS_START: "GET_NEXT_BLOGS_START",
    GET_NEXT_BLOGS_FAIL: "GET_NEXT_BLOGS_FAIL",
    GET_NEXT_BLOGS_SUCCESS: "GET_NEXT_BLOGS_SUCCESS",
}

const getNextStep = {
    GET_NEXT_STEP_START: "GET_NEXT_STEP_START",
    GET_NEXT_STEP_FAIL: "GET_NEXT_STEP_FAIL",
    GET_NEXT_STEP_SUCCESS: "GET_NEXT_STEP_SUCCESS",
}

const submitForm = {
    SUBMIT_FORM_START: "SUBMIT_FORM_START",
    SUBMIT_FORM_FAIL: "SUBMIT_FORM_FAIL",
    SUBMIT_FORM_SUCCESS: "SUBMIT_FORM_SUCCESS",
    SUBMIT_FORM_RESET: "SUBMIT_FORM_RESET",
}

const mobileSSO = {
    MOBILE_SSO_START: "MOBILE_SSO_START",
    MOBILE_SSO_FAIL: "MOBILE_SSO_FAIL",
    MOBILE_SSO_SUCCESS: "MOBILE_SSO_SUCCESS",
}

const actionTypes = {
    ...getBlogList,
    ...getBlogDetail,
    ...getNextBlogs,
    ...getNextStep,
    ...submitForm,
    ...mobileSSO,

    MOUNT_FORM: "MOUNT_FORM",
    UNMOUNT_FORM: "UNMOUNT_FORM",
    CLEAR_FORM: "CLEAR_FORM",
    CLEAR_FORM_ERROR: "CLEAR_FORM_ERROR",
    CLEAR_FORM_FIELD: "CLEAR_FORM_FIELD",
    UPDATE_FORM_VALUES: "UPDATE_FORM_VALUES",
    UPDATE_FORM_VALIDATION: "UPDATE_FORM_VALIDATION",
    CLEAR_BLOG_DETAIL: "CLEAR_BLOG_DETAIL",

    START_ANIMATION: "START_ANIMATION",
    END_ANIMATION: "END_ANIMATION",

    UPDATE_CURRENT_STEP: "UPDATE_CURRENT_STEP",
    UPDATE_USER_CODE: "UPDATE_USER_CODE",
    REDIRECT_TO: "REDIRECT_TO",
    SET_LOGGED_IN: "SET_LOGGED_IN",
    FETCH_AUTHENTICATION_START: "FETCH_AUTHENTICATION_START",
    FETCH_AUTHENTICATION_SUCCESS: "FETCH_AUTHENTICATION_SUCCESS",
    FETCH_CONTEXT_SUCCESS: "FETCH_CONTEXT_SUCCESS",
    GET_AVAILABLE_POSITIONS: "GET_AVAILABLE_POSITIONS",
    SHOW_REQUEST_DEMO_MODAL: "SHOW_REQUEST_DEMO_MODAL",
    HIDE_REQUEST_DEMO_MODAL: "HIDE_REQUEST_DEMO_MODAL",
}

export default actionTypes
