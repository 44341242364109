import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"

import "marketing-website/stylus/PillWrapper.styl"

const Pill = ({ title, icon, description }) => (
    <div
        className="pill-main"
    >
        <div className="pill-icon">
            <i className={`fa ${icon}`} />
        </div>
        <div>
            <div className="pill-title">{title}</div>
            <div className="pill-description">{description}</div>
        </div>
    </div>
)

Pill.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

const PillWrapper = ({ pills }) =>
    <Col
        lg={12}
        className="pills-wrapper"
    >
        {
            pills.map(pill =>
                <Pill
                    title={pill.title}
                    icon={pill.icon}
                    description={pill.description}
                />
            )
        }
    </Col>


PillWrapper.propTypes = {
    pills: PropTypes.array.isRequired,
}

export default PillWrapper
