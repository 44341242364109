/* eslint-disable no-undef */
import React from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Helmet } from "react-helmet"
import { Row, Col, FormControl, InputGroup, Button, ButtonGroup, DropdownButton, DropdownItem } from "react-bootstrap"

import SegueLink from "middleware/SegueLink"
import HeroImage from "marketing-website/components/HeroImage"
import BlogThumbnail from "marketing-website/components/BlogThumbnail"
import EmailRequest from "marketing-website/components/EmailRequest"
import BlogType from "shared/marketing-website/constants/blogType"
import Tooltip from "components/Tooltip"

import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"

import RESOURCES_PAGE_VALUES from "marketing-website/constants/resourcesPageValues"

import "marketing-website/stylus/ResourcesPage.styl"

const mapStateToProps = (state, props) => ({
    posts: selectors.makeBlogSliceSelector("posts")(state),
    blogListLoading: selectors.makeBlogSliceSelector("blogListLoading")(state),
    nextQuery: selectors.makeBlogSliceSelector("nextQuery")(state),
    formValues: selectors.selectResourceSearchFormValues(state, props),
    blogPostType: selectors.selectBlogPostType(state, props),
    advancedSearchTerm: selectors.selectAdvancedSearchTerm(state, props),
})

class ResourcesPage extends React.Component {
    constructor(props) {
        super(props)
        this.addFilter = this.addFilter.bind(this)
        this.loadNextBlogs = this.loadNextBlogs.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidMount() {
        const blogPostType = Object.values(BlogType).find((item) => item.urlPattern === this.props.params.postType)

        if (blogPostType) {
            this.props.updateForm("resourceSearch", { blog_post_type: blogPostType.value })
        }

        const queryString = this.props.serializeQuery()

        this.props.fetchBlogList(queryString).then(() => {
            if (window.location.hash) {
                // After fetching blogs, screen position is off. This is to set it to the anchor tag if an anchor tag is specified
                window.location.href = window.location.href
            }
        })
    }

    addFilter(blogPostType, nextUrl) {
        return (e) => {
            // The filter button is an 'a' tag with an href for SEO reasons.
            // Don't want to reload the page after each click, so we preventDefault
            e.preventDefault()

            const blogPostEnum = Object.values(BlogType).find((item) => item.value === blogPostType)

            // Since the 'All' filter button does not have a value in our BlogType constant, we are hardcoding 'All' into the event tracking.
            const blogPostName = blogPostEnum ? blogPostEnum.label : "All"

            // Event tracking what page they were on when they clicked a filter button, and which filter was selected.
            gtag("event", window.location.pathname, {
                event_category: "Filter",
                event_label: blogPostName,
            })

            this.props.updateForm("resourceSearch", { blog_post_type: blogPostType })

            // This changes the url to match the content they are viewing
            window.history.pushState("", "", nextUrl)

            const queryString = this.props.serializeQuery()
            this.props.fetchBlogList(queryString)
        }
    }

    loadNextBlogs() {
        this.props.fetchNextBlogs(this.props.nextQuery)
    }

    handleChange(e) {
        this.props.updateForm("resourceSearch", { advanced_search: e.target.value })
    }

    handleSearch() {
        gtag("event", window.location.pathname, {
            event_category: "Search",
            event_label: this.props.advancedSearchTerm,
        })
        const queryString = this.props.serializeQuery()
        this.props.fetchBlogList(queryString)
    }

    render() {
        const currentBlogPostType = Object.values(BlogType).find((item) => item.value === this.props.blogPostType)

        return (
            <div className="resources-page-main">
                <Helmet>
                    <title>
                        {currentBlogPostType ? currentBlogPostType.heroImageLabel : RESOURCES_PAGE_VALUES.title} |
                        Quorum
                    </title>
                    <meta
                        name="description"
                        content={
                            currentBlogPostType
                                ? currentBlogPostType.metaDescription
                                : RESOURCES_PAGE_VALUES.metaDescription
                        }
                    />
                    <meta
                        property="og:title"
                        content={`${
                            currentBlogPostType ? currentBlogPostType.heroImageLabel : RESOURCES_PAGE_VALUES.title
                        } | Quorum`}
                    />
                    <meta
                        property="og:description"
                        content={
                            currentBlogPostType
                                ? currentBlogPostType.metaDescription
                                : RESOURCES_PAGE_VALUES.metaDescription
                        }
                    />
                </Helmet>
                <HeroImage
                    title={currentBlogPostType ? currentBlogPostType.heroImageLabel : RESOURCES_PAGE_VALUES.title}
                    imgPath={currentBlogPostType ? currentBlogPostType.imgPath : RESOURCES_PAGE_VALUES.imgPath}
                    subtitle={currentBlogPostType ? currentBlogPostType.subtitle : RESOURCES_PAGE_VALUES.subtitle}
                    // If currentBlogPostType is defined, return false and do not show email form
                    // If currentBlogPostType is not defined, return true and show email form
                    showEmailRequest={!Boolean(currentBlogPostType)}
                    backgroundPositionY={currentBlogPostType ? undefined : RESOURCES_PAGE_VALUES.backgroundPositionY}
                />
                <Row className="resources-page-search">
                    <Col lg={10} lgOffset={1} md={10} mdOffset={1} sm={10} smOffset={1} xs={12} xsOffset={0}>
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Type to search our content..."
                                onChange={this.handleChange}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") this.handleSearch()
                                }}
                                value={this.props.advancedSearchTerm}
                            />
                            <InputGroup.Text onClick={this.handleSearch}>Search</InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={12}
                        lgOffset={0}
                        md={12}
                        mdOffset={0}
                        sm={10}
                        smOffset={1}
                        xsHidden
                        className="resources-page-button-group"
                    >
                        {RESOURCES_PAGE_VALUES.filters.map((filter) => (
                            <Tooltip key={filter.label} label={filter.subtitle}>
                                <SegueLink
                                    key={filter.label}
                                    to={filter.specificPostUrl || filter.url}
                                    onClick={filter.specificPostUrl ? null : this.addFilter(filter.value, filter.url)}
                                    className={`resources-page-button ${
                                        this.props.blogPostType === filter.value ? "active" : ""
                                    }`}
                                >
                                    {filter.label}
                                </SegueLink>
                            </Tooltip>
                        ))}
                    </Col>
                    <Col lgHidden mdHidden smHidden xs={12} xsOffset={0} className="resources-filter-mobile">
                        <ButtonGroup block justified>
                            <DropdownButton
                                title={currentBlogPostType ? currentBlogPostType.label : "All"}
                                id={currentBlogPostType ? currentBlogPostType.label : "All"}
                                className="resources-filter-dropdown"
                            >
                                {RESOURCES_PAGE_VALUES.filters.map((filter) => (
                                    <DropdownItem
                                        key={filter.label}
                                        onClick={this.addFilter(filter.value, filter.url)}
                                        className="resources-filter-menu-item"
                                    >
                                        {filter.label}
                                    </DropdownItem>
                                ))}
                            </DropdownButton>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="resources-page-content-wrapper">
                    <Col
                        lg={12}
                        lgOffset={0}
                        md={12}
                        mdOffset={0}
                        sm={12}
                        smOffset={0}
                        xs={12}
                        xsOffset={0}
                        className="resources-page-content"
                    >
                        <Row>
                            {this.props.posts.map((post) => (
                                <BlogThumbnail
                                    key={post.id}
                                    id={post.id}
                                    blogPostType={post.blog_post_type}
                                    title={post.title}
                                    imgPath={post.featured_image}
                                    shortCode={post.shortcode}
                                    logoImgPath={post.logo_image}
                                />
                            ))}
                        </Row>
                        {this.props.blogListLoading && (
                            <div className="resources-page-loading section-main-header">Loading...</div>
                        )}
                        {!this.props.blogListLoading && this.props.posts.length === 0 && (
                            <div className="resources-page-loading section-main-header">No Resources Found</div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} lgOffset={4} md={4} mdOffset={4} sm={4} smOffset={4} xs={10} xsOffset={1}>
                        {this.props.nextQuery !== null && !this.props.blogListLoading && (
                            <Button
                                bsSize="large"
                                className="btn-block resources-page-next-button"
                                onClick={this.loadNextBlogs}
                                active={false}
                            >
                                Load More
                            </Button>
                        )}
                    </Col>
                </Row>
                <Row className="email-request-form-wrapper">
                    <div id="subscribe" />
                    <Col md={8} mdOffset={2} sm={10} smOffset={1} xsHidden className="email-request-form">
                        <h3 className="email-signup-header">Sign Up For Our Content</h3>
                        <EmailRequest bsSize="large" label="Sign Up For Our Content" />
                    </Col>
                    <Col lgHidden mdHidden smHidden xs={10} xsOffset={1} className="email-request-form">
                        <h3 className="email-signup-header">Sign Up For Our Content</h3>
                        <EmailRequest bsSize="large" label="Sign Up For Our Content" shortPlaceholder />
                    </Col>
                </Row>
            </div>
        )
    }
}

ResourcesPage.propTypes = {
    fetchBlogList: PropTypes.func.isRequired,
    fetchNextBlogs: PropTypes.func.isRequired,
    serializeQuery: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    updateForm: PropTypes.func.isRequired,
    blogListLoading: PropTypes.bool.isRequired,
    advancedSearchTerm: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, actionCreators)(ResourcesPage)
