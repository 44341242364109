import React from "react"
import PropTypes from "prop-types"

import { Row, Col } from "react-bootstrap"
import { connect } from "react-redux"

import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"

import BlogThumbnail from "marketing-website/components/BlogThumbnail"
import EmailRequest from "marketing-website/components/EmailRequest"

import SegueLink from "middleware/SegueLink"

import "marketing-website/stylus/LatestInsights.styl"

import urls from "shared/marketing-website/constants/urls"

const mapStateToProps = (state, props) => ({
    posts: selectors.makeBlogSliceSelector("posts")(state),
})

class LatestInsights extends React.Component {
    componentDidMount() {
        // Number of blogs fetched to be displayed
        this.props.fetchBlogList(`?resources=true&featured=true&limit=${this.props.numInsights}`)
    }

    render() {
        return (
            <Row
                className="latest-insights-main"
            >
                <div
                    id="subscribe"
                />
                <Col
                    md={10}
                    mdOffset={1}
                    sm={10}
                    smOffset={1}
                    xs={10}
                    xsOffset={1}
                    className="latest-insights-header"
                >
                    <h2 className="latest-insights-header-text">
                        Our Latest Public Affairs Insights
                    </h2>
                    <SegueLink to={urls.resources}>
                        <span
                            className="latest-insights-cta-large"
                        >
                            See All
                            <i className="fa fa-chevron-right" />
                        </span>
                        <Col
                            lgHidden
                            mdHidden
                            sm={10}
                            smOffset={1}
                            xs={10}
                            xsOffset={1}
                            className="latest-insights-cta-small"
                        >
                            See All
                            <i className="fa fa-chevron-right" />
                        </Col>
                    </SegueLink>
                </Col>
                <Col
                    md={10}
                    mdOffset={1}
                    sm={10}
                    smOffset={1}
                    xs={10}
                    xsOffset={1}
                    className="latest-insights-content"
                >
                    {
                        this.props.posts.map(post => (
                            <BlogThumbnail
                                key={post.id}
                                id={post.id}
                                blogPostType={post.blog_post_type}
                                title={post.title}
                                imgPath={post.featured_image}
                                shortCode={post.shortcode}
                                logoImgPath={post.logo_image}
                                fontSize={26}
                            />
                        ))
                    }
                </Col>
                <Col
                    md={8}
                    mdOffset={2}
                    sm={10}
                    smOffset={1}
                    xsHidden
                    className="email-request-form latest-insights-email"
                >
                    <EmailRequest
                        bsSize="large"
                        label="Latest Insights"
                    />
                </Col>
                <Col
                    lgHidden
                    mdHidden
                    smHidden
                    xs={10}
                    xsOffset={1}
                    className="email-request-form latest-insights-email"
                >
                    <EmailRequest
                        bsSize="large"
                        label="Latest Insights"
                        shortPlaceholder
                    />
                </Col>
            </Row>
        )
    }
}

LatestInsights.defaultProps = {
    numInsights: 4,
}

LatestInsights.propTypes = {
    fetchBlogList: PropTypes.func.isRequired,
    posts: PropTypes.array.isRequired,
    numInsights: PropTypes.number
}

export default connect(mapStateToProps, actionCreators)(LatestInsights)
