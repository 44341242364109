import React from "react"
import HeroImage from "marketing-website/components/HeroImage"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import PRESS_VALUES from "marketing-website/constants/pressValues"

import "marketing-website/stylus/PressPage"
import "marketing-website/stylus/Section"

import { generateStaticUrl } from "imports/desktopHelperFunctions"

const PressInline = ({url, logo, publication, title}) => (
    <Col
        lg={4}
        md={4}
        sm={6}
        xs={12}
        className="press-inline"
    >
        <a
            className="blue-image"
            target="_blank"
            href={url}
        >
            <div className="image-holder well">
                <span
                    className="publication-image"
                    style={{
                        backgroundImage: `url(${PRESS_VALUES.pressLogoImgPaths[logo]}`
                    }}
                />
            </div>
        </a>
        <div>
            <p className="section-text-main">
                {title}
            </p>
            <div className="read-more">
                <a
                    target="_blank"
                    href={url}
                >
                    Read More
                    {" "}
                    <i className="fa fa-chevron-right" />
                </a>
            </div>
        </div>
    </Col>
)

PressInline.propTypes = {
    link: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    publication: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

const PressPage = (props) => {
    const renderInlines = () => (
        PRESS_VALUES.pressPieces.map(item =>
            <PressInline
                key={item.url}
                {...item}
            />
        )
    )

    return (
        <div className="press-page">
            <Helmet>
                <title>{PRESS_VALUES.metaTitle}</title>
                <meta
                    name="description"
                    content={PRESS_VALUES.metaDescription}
                />
                <meta
                    property="og:title"
                    content={PRESS_VALUES.metaTitle}
                />
                <meta
                    property="og:description"
                    content={PRESS_VALUES.metaDescription}
                />
            </Helmet>
            <HeroImage
                imgPath={PRESS_VALUES.imgPath}
                title={PRESS_VALUES.title}
                subtitle={PRESS_VALUES.subtitle}
                backgroundPositionY="30%"
            />
            <Row
                className="press-page-content"
            >
                <Col
                    lg={10}
                    lgOffset={1}
                    md={10}
                    mdOffset={1}
                    sm={10}
                    smOffset={1}
                    xs={10}
                    xsOffset={1}
                >
                    <Row>
                        {renderInlines()}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default PressPage
