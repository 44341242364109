import React from "react"
import VisibleSection from "marketing-website/components/VisibleSection"
import { Row, Col, Button } from "react-bootstrap"
import PropTypes from "prop-types"
import "marketing-website/stylus/TestimonialSection.styl"

const TestimonialSection = ({ quote, personName, companyName }) => (
    <VisibleSection
        className="testimonial-section-wrapper"
    >
        <Row className="section-holder testimonial-text-holder">
            <Col
                md={8}
                mdOffset={2}
                sm={10}
                smOffset={1}
                xs={10}
                xsOffset={1}
            >
                <h3 className="testimonial-text">
                    {quote}
                </h3>
            </Col>
        </Row>
        <Row className="section-holder">
            <Col
                md={8}
                mdOffset={2}
                sm={10}
                smOffset={1}
                xs={10}
                xsOffset={1}
            >
                <h3 className="testimonial-attribution">
                    {personName}, <span className="company-name">{companyName}</span>
                </h3>
            </Col>
        </Row>
    </VisibleSection>
)

TestimonialSection.propTypes = {
    quote: PropTypes.string,
    personName: PropTypes.string,
    companyName: PropTypes.string,
}

export default TestimonialSection
