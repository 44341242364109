import React from "react"
import { Row, Col } from "react-bootstrap"
import VisibleSection from "marketing-website/components/VisibleSection"

import "marketing-website/stylus/ClientSection.styl"

const ClientSection = () => (
    <VisibleSection
        className="client-section"
    >
        <Row className="section-holder client-section-holder">
            <Row key="client-titles">
                <Col
                    lg={8}
                    lgOffset={2}
                    md={8}
                    mdOffset={2}
                    sm={10}
                    smOffset={1}
                    xs={10}
                    xsOffset={1}
                    className="client-text-holder"
                >
                    <h2 className="section-main-header">
                        Take Their Word For It
                    </h2>
                    <div>
                        <span className="section-text-main">
                            Quorum empowers thousands of public affairs professionals across Fortune 500 companies, trade associations, nonprofits, advocacy groups, congressional offices, and embassies.
                        </span>
                    </div>
                </Col>
            </Row>
        </Row>
    </VisibleSection>
)

export default ClientSection
