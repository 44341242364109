import React from "react"
import HeroImage from "marketing-website/components/HeroImage"
import HeroImageBottomBanner from "marketing-website/components/HeroImageBottomBanner"
import {
    legislativeTrackingMeta,
    legislativeTrackingProductPanels,
    legislativeTrackingHeroImage,
} from "marketing-website/constants/legislativeTrackingPageValues"

const legislativeTrackingSoftwareHeroImage = {
    component: HeroImage,
    props: {
        ...legislativeTrackingHeroImage.props,
        title: "Legislative Tracking",
        showEmailRequest: false,
        isDemoRequest: false,
        fullHeaderRequestForm: true,
        children: <HeroImageBottomBanner />,
        checklistHeader: "In your online demo, you'll get:",
        checklistItems: [
            "An overview of the Quorum Platform",
            "A personalized plan to organize your legislative priorities by issue",
            "An explanation of how Quorum can help your team succeed",
        ]
    }
}

const LEGISLATIVE_TRACKING_SOFTWARE_PAGE_VALUES = {
    meta: legislativeTrackingMeta,
    componentDetails: [
        legislativeTrackingSoftwareHeroImage,
        ...legislativeTrackingProductPanels,
    ]
}

export default LEGISLATIVE_TRACKING_SOFTWARE_PAGE_VALUES
