import HeroImage from "marketing-website/components/HeroImage"
import ProductShow from "marketing-website/components/ProductShow"
import FeaturedArticle from "marketing-website/components/FeaturedArticle"

import { generateStaticUrl } from "imports/desktopHelperFunctions"

const STAKEHOLDER_PRODUCT_VALUES = {
    meta: {
        title: "Stakeholder Engagement",
        description: "Centralize, track and activate your relationships with Quorum's public affairs software.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                backgroundPositionY: "50%",
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/stakeholder_banner.jpg"),
                subtitle: "Activate your relationships with the world’s first public affairs CRM.",
                title: "Stakeholder Engagement",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                altText: "store-everything-one-place",
                id: "one-central-database",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/central_database_2.png"),
                key: "legislative-tracking",
                sectionMainHeader: "One Central Database",
                sectionLeadHeader: "Store Everything in One Place",
                sectionText: "Upload and store your custom database of stakeholder information in Quorum",
                textAlignedLeft: true,
            },
        },
        {
            component: ProductShow,
            props: {
                altText: "advanced-mapping-tools",
                id: "advanced-mapping-tools",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/advanced-mapping-tools.png"),
                key: "advanced-mapping-tools",
                sectionMainHeader: "Advanced Stakeholder Mapping Tools",
                sectionLeadHeader: "Visualize your Network",
                sectionText: "Create dynamic visualizations of all interactions and relationships logged in your organization’s Quorum account.",
                textAlignedLeft: false,
                theme: "section-purple",
            },
        },
        {
            component: ProductShow,
            props: {
                altText: "personalize-communication",
                id: "customized-email-tools",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/integrated_email_tool_2.png"),
                key: "mobile-app",
                sectionMainHeader: "Integrated Email Tool",
                sectionLeadHeader: "Personalize Your Communication",
                sectionText: "With the click of a button, send personalized messages to all your stakeholders at once. Track engagement metrics including who opened your email and clicked on the links.",
                textAlignedLeft: true,
            },
        },
        {
            component: ProductShow,
            props: {
                altText: "stay-on-top-of-conversation",
                id: "social-media-tracking",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/social_media_tracking_2.png"),
                key: "outbox",
                sectionMainHeader: "Social Media Tracking",
                sectionLeadHeader: "Stay On Top Of The Conversation",
                sectionText: "With Quorum’s social add-on, you can monitor what your stakeholders are saying on social media and create email alerts to notify you the moment they mention issues you care about.",
                textAlignedLeft: false,
                theme: "section-grey",
            },
        },
        {
            component: ProductShow,
            props: {
                altText: "identify-key-relationships",
                id: "comprehensive-survey-tools",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/identify-key-relationships.png"),
                key: "spreadsheet-creator",
                sectionMainHeader: "“Who Do You Know” Survey",
                sectionLeadHeader: "Identify Key Relationships",
                sectionText: "Use Quorum’s ambassador add-on to survey your members, advocates or employees and identify existing relationships with elected officials.",
                textAlignedLeft: true,
                theme: "section-blue",
            },
        },
        {
            component: ProductShow,
            props: {
                altText: "never-miss-interaction",
                id: "interaction-logger",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/never-miss-interaction.png"),
                key: "member-profiles",
                sectionMainHeader: "Interaction Logger",
                sectionLeadHeader: "Never Miss an Interaction",
                sectionText: "Quorum’s ambassador add-on enables your ambassadors to report notes from fly-ins and in-district meetings through Quorum’s interaction logger, ensuring you stay aware of contact with legislators.",
                textAlignedLeft: false,
            },
        },
    ]
}

export default STAKEHOLDER_PRODUCT_VALUES
