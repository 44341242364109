import React from "react"
import { connect } from "react-redux"
import * as actionCreators from "marketing-website/action-creators"
import { DropdownItem } from "react-bootstrap"
import PropTypes from "prop-types"

export const NavigationMenuItem = (props) => {
    const segueToUrl = (e) => {
        e.preventDefault()
        props.segue(props.url)
        // Trigger onSelect callback to close nav dropdown menu
        props.onSelect()
    }

    return (
        <DropdownItem eventKey={props.eventKey} className="nav-menu-item" href={props.url} onClick={segueToUrl}>
            {props.text}
        </DropdownItem>
    )
}

NavigationMenuItem.propTypes = {
    eventKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    segue: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
}

export default connect(() => ({}), { ...actionCreators })(NavigationMenuItem)
